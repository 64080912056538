import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import Moment from "react-moment";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import AuthService from "../../Api/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../Utils/CustomAlertMessage";
import { $ } from "react-jquery-plugin";
import { ProfileContext } from "../../Context";
import DataTableBase from "../../Utils/DataTable";
import copy from 'copy-to-clipboard';
import '../../Utils/Loading/Loading.css'

const FundPage = () => {
  const [fundData, setfundData] = useState([]);
  const [allfundData, setAllfundData] = useState([]);
  const [estimatedportfolio, setEstimatedportfolio] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [selectedDeposit, setSelectDeposit] = useState("");
  const [selectedChain, setSelectedchain] = useState("");
  const [tradeHistory, settradeHistory] = useState([]);
  const [minDeposit, setminDeposit] = useState();
  const [walletHisDetail, setwalletHisDetail] = useState();
  const [tradeHisDetails, settradeHisDetails] = useState();
  const [currencyId, setcurrencyId] = useState();
  const [allChain, setallChain] = useState();
  const [walletAddress, setWalletAddress] = useState('');
  const [DepositHistory, setDepositHistory] = useState([]);
  const [WithdrawalHistory, setWithdrawalHistory] = useState([]);
  const [wihtdrawalDetails, setwihtdrawalDetails] = useState({ minWithdrawal: '', maxWithdrawal: '', withdrawalFee: '' });
  const [otp, setOtp] = useState('');
  const [skipCount, setSkipCount] = useState(0);
  const [limitCount, setLimitCount] = useState(0);
  const [swapHistory, setSwapHistory] = useState([])
  const [p2pWallet, setP2pWallet] = useState([]);
  const [amountValidation, setAmountValidation] = useState({ min: 0, max: 0 });
  const { email, mobile, handleUserDetials } = useContext(ProfileContext);

  // ********* Get OTP ********** //
  const handleGetOtp = async (signId) => {
    if (!walletAddress || !withdrawAmount) {
      alertErrorMessage('Please enter withdraw amount and Wallet address');
      return;
    } else if (CheckWalletAddress) {
      alertErrorMessage('Please enter valid Wallet Address')
      return;
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.withdrawalOtp(signId, walletAddress, withdrawAmount, selectedCurrency, 'verification').then(async (result) => {
      if (result?.success) {
        startTimer();
        alertSuccessMessage(result?.message);
      }
    });
  };

  // ********* Funds Data ********** //
  const getUserfunds = async (orderId) => {
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.getUserfunds(orderId)
      const coins = await AuthService.getCoinList()
      if (result?.success && coins?.success) {
        let filteredData = result?.data?.map((wallet) => {
          const matchingCoin = coins?.data?.find(coin => coin?._id === wallet?.currency_id);
          return {
            ...wallet,
            p2pStatus: matchingCoin ? matchingCoin?.p2p : undefined
          };
        })?.filter((data) => data?.short_name !== "INR")
        setfundData(filteredData)
        setAllfundData(filteredData)
        return
      } else if (result?.success) {
        setfundData(result?.data)
        setAllfundData(result?.data)
      }
    } catch (error) {
      console.log(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false) }

  };

  // ********* Coin Deposit Address ********** //
  const getDepostAddress = async (currId, chain) => {
    setSelectedchain(chain);
    setSelectDeposit("");
    LoaderHelper.loaderStatus(true);
    await AuthService.generateAddress(currId, chain).then((result) => {
      if (result?.success) {
        setSelectDeposit(result?.data)
      } else {
        $('#Deposit_modal')?.modal('hide');
      }
    })
  };

  // ********* Estimated Portfolio********** //
  const EstimatedPortfolio = async () => {
    await AuthService.estimatedPortfolio().then((result) => {
      if (result?.success) {
        setEstimatedportfolio(result?.data)
      };
    })
  };

  // ********* Trade History ********** //
  const TradeHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.tradeHistory(skip, 10).then((result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          settradeHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
          return;
        }
      }
    })
  };

  // ********* Deposit Wallet History ********** //
  const DepositWalletHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.walletDepositHistory(skip, 10).then(async (result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setDepositHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      }
    });
  };

  // ********* Deposit Wallet History ********** //
  const WithdrawalWalletHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.walletWithdrawalHistory(skip, 10).then(async (result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setWithdrawalHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      }
    });
  };

  // ********* Coin Min Deposit Data ********** //
  const coinDetails = async (currId, type, data) => {
    try {
      LoaderHelper.loaderStatus(true)
      await AuthService.coinDetails(currId).then((result) => {
        if (result?.success) {
          setminDeposit(result?.data?.min_deposit);
          setwihtdrawalDetails({ minWithdrawal: result?.data?.min_withdrawal, maxWithdrawal: result?.data?.max_withdrawal, withdrawalFee: result?.data?.withdrawal_fee })

          if (type === 'deposit') {
            if (result?.data?.deposit_status === "INACTIVE") {
              alertErrorMessage('Deposit is disabled for this Coin');
              return;
            } else {
              getDepostAddress(data.currency_id, data.chain[0]);
              $("#Deposit_modal").modal("show");
            }
          }

          if (type === 'withdrawal') {
            if (result?.data?.withdrawal_status === 'INACTIVE') {
              alertErrorMessage('Withdrawal is disabled for this Coin');
              return;
            } else {
              setAmountValidation({ min: result?.data?.min_withdrawal, max: result?.data?.max_withdrawal })
              $("#Withdraw_modal").modal("show");
            }

          }
        }
      }
      )
    } finally { LoaderHelper.loaderStatus(false) }
  };

  // ********* Withdrawal Currency********** //
  const WithdrawalCurrency = async (currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId) => {
    if (!email) {
      alertErrorMessage('Please update your email in Profile section for Withdrawal');
      return
    }
    LoaderHelper.loaderStatus(true);
    const result = await AuthService.withdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId)
    if (result?.success) {
      $("#Withdraw_modal2").modal("hide");
      getUserfunds()
      alertSuccessMessage(result?.message);
    }
  };

  //******** Auto Call Verify -Deposit after evry 5 minute **********//
  const VerifyDeposit = async () => {
    const ref = window.location.href.split("/");
    ref[ref.length - 1] === 'FundPage' && setTimeout(VerifyDeposit, 300000);
    await AuthService.verifyDeposit()
  };

  //******** Wallet Address Validation **********//
  const [CheckWalletAddress, setCheckWalletAddress] = useState(false);
  const handleWalletAddress = (e) => {
    // setCheckWalletAddress(true);
    setWalletAddress(e.target.value)
    // if (selectedChain === 'BEP20' || selectedChain === 'ERC20' || selectedChain === 'WCCEX20') {
    //   const bep20Regex = /^(0x)?[0-9a-fA-F]{40}$/;
    //   if (bep20Regex.test(e.target.value)) {
    //     setCheckWalletAddress(false);
    //   }
    // } else if (selectedChain === 'TRC20') {
    //   const trc20Regex = /^(T|t)[A-Za-z1-9]{33}$/;
    //   if (trc20Regex.test(e.target.value)) {
    //     setCheckWalletAddress(false);
    //   }
    // }
  };

  //****** */ Function to start the timer and disable the button***********//
  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const startTimer = () => {
    setIsButtonDisabled(true);
    setTimer(60);
  };

  const handleWithdrawModal = (data) => {
    setAmountValidation({ min: 0, max: 0 })
    setcurrencyId(data.currency_id);
    setallChain(data.chain);
    setSelectedCurrency(data.short_name);
    setSelectedchain(data.chain[0]);
    setWalletAddress('');
    setWithdrawAmount('');
    coinDetails(data?.currency_id, 'withdrawal');
    setCheckWalletAddress(false);
    setOtp('')
  };

  const handleDepositModal = (data) => {
    coinDetails(data?.currency_id, 'deposit', data);
    setSelectedCurrency(data.short_name);
    setallChain(data.chain);
    setcurrencyId(data.currency_id)
  };

  const SwapHistory = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.swapHistory().then(async (result) => {
      if (result?.success) {
        setSwapHistory(result?.data?.reverse());
      }
    });
  };

  const HandlePairSearch = (e) => {
    let input = e.target.value?.toLowerCase();
    let filteredData = allfundData?.filter((item) => {
      return item?.short_name?.toLowerCase()?.includes(input)
    });
    setfundData(filteredData)
  };

  const userP2PWallet = async (orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.userP2pWallet(orderId).then((result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setP2pWallet(result?.data);
        } catch (error) {

        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };

  const p2pTransferModal = (item) => {
    $("#p2p_modal").modal("show");
    setSelectedCurrency(item.short_name)
    setWithdrawAmount("")
  };

  const spotTransferModal = (item) => {
    $("#spot_modal").modal("show");
    setSelectedCurrency(item.short_name)
    setWithdrawAmount("")
  };

  const transferHandler = async (selectedCurrency, withdrawAmount) => {
    if (!withdrawAmount || withdrawAmount <= 0) {
      alertErrorMessage("Enter amount to transfer")
      return
    };
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.transferToP2P(selectedCurrency, withdrawAmount)
      if (result?.success) {
        alertSuccessMessage(`${withdrawAmount} ${selectedCurrency} transferred to P2P wallet`)
        userP2PWallet();
        getUserfunds();
        SwapHistory()
        $("#p2p_modal").modal("hide");
      } else {
        alertErrorMessage(result?.message)
      }
    } finally { LoaderHelper.loaderStatus(false) }
  };

  const transferHandlerToSpot = async (selectedCurrency, withdrawAmount) => {
    if (!withdrawAmount || withdrawAmount <= 0) {
      alertErrorMessage("Enter amount to transfer")
      return
    };
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.transferToSpot(selectedCurrency, withdrawAmount)
      if (result.success) {
        alertSuccessMessage(result.message)
        userP2PWallet();
        getUserfunds();
        SwapHistory()
        $("#spot_modal").modal("hide");
      } else {
        alertErrorMessage(result.message)
      }
    } finally { LoaderHelper.loaderStatus(false) }
  };

  const fundsAction = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        <a href="#/" className="tb_btn badge bg-success" onClick={() => handleDepositModal(row)}>Deposit</a>
        <a href="#/" className="tb_btn  badge bg-danger  m-1" onClick={() => handleWithdrawModal(row)}>Withdraw</a>
        {row?.p2pStatus === true && <button className=" btn btn-primary btn-sm " disabled={+row?.balance <= 0} onClick={() => p2pTransferModal(row)} > Transfer to P2P Wallet </button>}

      </div>
    )
  };

  const p2pAction = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        <button className=" btn btn-success btn-sm mx-1" disabled={+row?.p2p_balance <= 0} onClick={() => spotTransferModal(row)}  > Transfer to Spot Wallet </button>

      </div>
    )
  };

  const fundsColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + 1 },
    { name: <>ASSETS</>, selector: row => row.short_name, },
    { name: <>Available Balance</>, wrap: true, sortable: true, selector: row => parseFloat(row?.balance?.toFixed(8)) },
    { name: <>Locked</>, wrap: true, sortable: true, selector: row => parseFloat(row?.locked_balance?.toFixed(8)) },
    { name: <>Total</>, wrap: true, sortable: true, selector: row => parseFloat((+row?.balance + +row?.locked_balance)?.toFixed(8)), },
    { name: "Action", wrap: true, grow: 1, selector: fundsAction }
  ];

  const p2pWalltColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + 1 },
    { name: <>ASSETS</>, selector: row => row.short_name, },
    { name: <>P2P Balance</>, wrap: true, sortable: true, selector: row => parseFloat(row?.p2p_balance?.toFixed(8)) },
    { name: <>P2P Locked</>, wrap: true, sortable: true, selector: row => parseFloat(row?.p2p_locked_balance?.toFixed(8)) },
    { name: "Action", wrap: true, grow: 1, selector: p2pAction }
  ];

  const p2pSwapColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + 1 },
    { name: <>Date/Time</>, compact: 1, selector: (row, index) => <Moment date={row?.updatedAt} format="DD-MM-YYYY, h:mm:ss" /> },
    { name: <>Coin</>, selector: row => row.short_name, },
    { name: <>Amount</>, wrap: true, sortable: true, selector: row => parseFloat(row?.amount?.toFixed(8)) },
    { name: <>Description</>, wrap: true, sortable: true, selector: row => row?.wallet },
  ];

  const handleCryptoCurr = (row) => {
    return <>
      {row?.currency}
    </>
  }

  const tradeHistoryColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, wrap: true, selector: row => <Moment date={row?.updatedAt} format="DD-MM-YYYY, h:mm:ss" />, },
    { name: <>Trading Currency</>, wrap: true, selector: handleCryptoCurr },
    { name: <>Side</>, wrap: true, selector: row => row?.side },
    { name: <>Order Type</>, wrap: true, selector: row => row?.order_type, },
    { name: <>Price</>, wrap: true, sortable: true, selector: row => parseFloat(row?.price) },
    { name: <>Executed</>, wrap: true, sortable: true, selector: row => parseFloat(row?.quantity?.toFixed(8)) },
    { name: <>Total</>, wrap: true, selector: row => parseFloat((row?.price * row?.quantity)?.toFixed(5)) },
    // { name: <>Transaction Type</>, wrap: true, selector: row => row?.transaction_type },
    { name: <>Fee</>, wrap: true, sortable: true, selector: row => parseFloat(row?.fee?.toFixed(8)) }
  ];

  const withdrawalColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, selector: row => <Moment date={row?.updatedAt} format="DD-MM-YYYY hh:mm" /> },
    { name: <>Coin</>, selector: row => row.short_name, },
    { name: <>Amount</>, selector: row => parseFloat(row.amount?.toFixed(5)), },
    { name: <>Transaction Type</>, selector: row => row.transaction_type, },
    { name: <>Status</>, selector: row => row.status, },
  ];

  const depositColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, selector: row => <Moment date={row?.updatedAt} format="DD-MM-YYYY hh:mm" /> },
    { name: <>Coin</>, selector: row => row.short_name, },
    { name: <>Amount</>, selector: row => parseFloat(row.amount?.toFixed(5)), },
    { name: <>Transaction Type</>, selector: row => row.transaction_type, },
    { name: <>Status</>, selector: row => row.status, },
  ];

  useEffect(() => {
    let interval;
    if (timer > 0 && isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer, isButtonDisabled]);


  const executeFuncInSequence = async () => {
    await getUserfunds();
    await handleUserDetials();
    await EstimatedPortfolio();
    await userP2PWallet();
    await SwapHistory();
    await VerifyDeposit();
  }

  useEffect(() => {
    executeFuncInSequence()
  }, []);

  const tableLoadingComp = (
    <div className=" p-5">
      <div class="spinner-border text-light p-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  )

  return (
    <>
      <div className="wallet_bal_sec" >
        <section className="inner-page-banner">
          <div className="container">
            <div className="card p-md-3">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-md-7">
                    <div className="wallet__details">
                      <div className="wallet__info">Total Portfolio Value</div>
                      <div className="wallet__currency">
                        <div className="wallet__number">USDT {estimatedportfolio?.dollarPrice || "0"} </div>
                      </div>
                      <div className="wallet__price">{estimatedportfolio && `${estimatedportfolio?.Currency} : ${estimatedportfolio?.[estimatedportfolio?.Currency]}`} </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="searchBar custom-tabs"><i className="ri-search-2-line"></i>
                      <input type="search" className="custom_search" placeholder="Search Assets" onChange={HandlePairSearch} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section >
          <div className="container">
            <div className="d-flex-between mb-5 custom_dlflex">
              <ul className="nav custom-tabs overflowx_scroll funds_tab">
                <li><a className=" active" data-bs-toggle="tab" href="#funds" onClick={getUserfunds}>Funds</a></li>
                <li > <a data-bs-toggle="tab" href="#p2pWallet"> P2P Wallet</a></li>
                <li ><a data-bs-toggle="tab" href="#p2pSwap"> P2P Swap History</a></li>
                <li><a data-bs-toggle="tab" href="#tt_history" onClick={() => { DepositWalletHistory(0, 10); setSkipCount(0); setLimitCount(0) }}> Deposit History</a> </li>
                <li><a data-bs-toggle="tab" href="#withdrwal_history" onClick={() => { WithdrawalWalletHistory(0, 10); setSkipCount(0); setLimitCount(0) }}> Withdrawal History</a> </li>
                <li><a data-bs-toggle="tab" href="#tradehistory" onClick={() => { TradeHistory(0, 10); setSkipCount(0); setLimitCount(0) }}>Trade History</a></li>
              </ul>
            </div>

            <div className="tab-content custom-tab-content p-0 mt-3">

              {/* Funds Wallet  */}
              <div className="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="funds">
                <div className="table-responsive">
                  {fundData ? <DataTableBase columns={fundsColumn} data={fundData} noDataComponent={tableLoadingComp} /> : <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>}

                </div>
              </div>

              {/* P2P Wallet  */}
              <div className="tab-pane fade  container  form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="p2pWallet">
                <div className="table-responsive">
                  <DataTableBase columns={p2pWalltColumn} data={p2pWallet} noDataComponent={tableLoadingComp} />

                </div>
              </div>

              {/* P2P Swap History */}
              <div className="tab-pane fade  container  form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="p2pSwap">
                <div className="table-responsive">
                  <DataTableBase columns={p2pSwapColumn} data={swapHistory} />

                </div>
              </div>

              {/* Deposit History */}
              <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tt_history">
                <div className="table-responsive">
                  <DataTableBase columns={depositColumn} data={DepositHistory} pointerOnHover onRowClicked={(row) => { setwalletHisDetail(row); $("#wallet_history").modal('show'); }} />
                  {DepositHistory?.length !== 0 &&
                    <div className="container pt-3 pb-4  table_control" >
                      <div className=" row align-items-center " >
                        <div className=" row align-items-center justify-content-end" >
                          <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                            <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                            <label className="btn " htmlFor="btnradio250" onClick={() => { DepositWalletHistory(skipCount - 10, limitCount - 10) }}>Previous</label>

                            <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" disabled={DepositHistory?.length < 10} />
                            <label className="btn " htmlFor="btnradio375" disabled onClick={() => { DepositWalletHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                          </div>
                        </div>
                      </div>
                    </div>}
                </div>
              </div>

              {/* Withdrawal History */}
              <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="withdrwal_history">
                <div className="table-responsive">
                  <DataTableBase columns={withdrawalColumn} data={WithdrawalHistory} pointerOnHover onRowClicked={(row) => { setwalletHisDetail(row); $("#wallet_history").modal('show'); }} />
                  {WithdrawalHistory?.length !== 0 &&
                    <div className="container pt-3 pb-4  table_control" >
                      <div className=" row align-items-center " >
                        <div className=" row align-items-center justify-content-end" >
                          <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                            <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                            <label className="btn " htmlFor="btnradio250" onClick={() => { WithdrawalWalletHistory(skipCount - 10, limitCount - 10) }}>Previous</label>

                            <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" disabled={WithdrawalHistory?.length < 10} />
                            <label className="btn " htmlFor="btnradio375" disabled onClick={() => { WithdrawalWalletHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                          </div>
                        </div>
                      </div>
                    </div>}
                </div>
              </div>

              {/* *****Trade History******** */}
              <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tradehistory">
                <div className="table-responsive">
                  <DataTableBase subHeaderWrap headerWrap columns={tradeHistoryColumn} pointerOnHover data={tradeHistory} onRowClicked={(row) => { settradeHisDetails(row); $("#trade_history").modal('show'); }} />

                  {tradeHistory?.length !== 0 &&
                    <div className="container pt-3 pb-4  table_control" >
                      <div className=" row align-items-center justify-content-end" >
                        <div className="col-md-4" >
                          <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                            <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                            <label className="btn " htmlFor="btnradio250" onClick={() => { TradeHistory(skipCount - 10, limitCount - 10) }}>Previous</label>
                            <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off"
                              disabled={tradeHistory?.length < 10} />
                            <label className="btn " htmlFor="btnradio375" disabled onClick={() => { TradeHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                          </div>
                        </div>
                      </div>
                    </div>}
                </div>
              </div>


            </div>
          </div>
        </section >
        <div className="modal fade" id="Deposit_modal" tabIndex="-1" aria-labelledby="Deposit_modalLaebl" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header flex-column px-8">
                <h3 className="modal-title" id="placeBitLaebl">
                  Deposit Funds
                </h3>
                <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                  <i className="ri-close-fill"></i>
                </button>
              </div>
              <div className="modal-body px-8 py-5">
                <form action="#">
                  <>
                    <div id="bnm">
                      <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                        {allChain ? allChain?.map((item, index) => {
                          return (
                            <button key={index} type="button" className={`btn btn-outline-primary ${selectedChain === item && "active"}`} htmlFor={item} onClick={() => { getDepostAddress(currencyId, item) }}>
                              {item === 'RIK' ? 'RIK20' : item}
                            </button>)
                        }) : ''}
                      </div>
                      {selectedDeposit ? <>   <img alt="Oops... Error while fetching QR " src={`https://quickchart.io/chart?chs=300x300&cht=qr&chl=${selectedDeposit}&choe=UTF-8`}
                        className="qr_img img-fluid" />
                        <input className="shareUrl-input copy_url js-shareUrl text-center" type="text" readOnly value={selectedDeposit || "Error while fetching deposit address"} onClick={() => { copy(selectedDeposit); alertSuccessMessage("Deposit Address Copied") }}
                        />
                        <small className="text-center d-block mtb-2">
                          Click above to copy the Code.
                        </small> </> :
                        <input className="shareUrl-input copy_url js-shareUrl text-center" type="text" readOnly value={selectedDeposit || "Error while fetching deposit address"} />
                      }
                      <ul className="disclaimer mt-3">

                        <label>Disclaimer</label>
                        <li>
                          • Minimum deposit of {minDeposit} {selectedCurrency}, deposit
                          below that cannot be recovered.
                        </li>
                        <li>
                          • Please deposit only {selectedCurrency} on this
                          address. If you deposit any other coin, it will be
                          lost forever.
                        </li>
                        <li>
                          • This is {selectedChain === 'RIK' ? 'RIK20' : selectedChain} deposit address type.
                          Transferring to an unsupported network could result in
                          loss of deposit.
                        </li>
                      </ul>
                    </div>
                  </>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Withdrwal modal first  */}
        <div className="modal fade" id="Withdraw_modal" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true" >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header flex-column px-8">
                <h3 className="modal-title" id="placeBitLaebl">
                  Withdraw Funds
                </h3>
                <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                  <i className="ri-close-fill"></i>
                </button>
              </div>
              <div className="modal-body px-8 py-5">
                <>
                  <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                    {allChain ? allChain?.map((item, index) => {
                      return (
                        <button key={index} type="button" className={`btn btn-outline-primary ${selectedChain === item && "active"}`} htmlFor={item} onClick={() => { setSelectedchain(item); setWalletAddress("") }}>
                          {item === 'RIK' ? 'RIK20' : item}
                        </button>)
                    }) : ''}
                  </div>

                  <div className="form-group mb-4">
                    <input type="text" name="wallet_Add" value={walletAddress} placeholder="Wallet Address" onChange={(e) => handleWalletAddress(e)} />
                    <small className="text-danger">{CheckWalletAddress && walletAddress ? 'Please enter valid Wallet Address' : ''}</small>
                  </div>

                  <div className="form-group mb-4">
                    <input type="number" name="amount_val" value={withdrawAmount} placeholder="Amount" onChange={(e) => setWithdrawAmount(e.target.value)} onWheel={(e) => e.target.blur()} />
                  </div>
                  <div className="field-box field-otp-box  mb-4">
                    <input type="number" name="wallet_Add" value={otp} placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} onWheel={(e) => { e.target.blur() }} />
                    <button type="btn" className="btn btn-sm btn-gradient" disabled={isButtonDisabled} onClick={() => handleGetOtp(email ? email : mobile, true)}>
                      <span> {isButtonDisabled ? `Resend OTP in ${timer} sec` : 'Get OTP'}</span>
                    </button>
                    <small className="d-block text-center mt-2">OTP will sent to {email ? email : mobile}</small>
                  </div>
                  <ul className="disclaimer mt-3">
                    <label>Disclaimer</label>
                    <li>
                      • Minimum Withdrawal should be of {wihtdrawalDetails.minWithdrawal}
                    </li>
                    <li>
                      • Maximum Withdrawal should be of : {wihtdrawalDetails.maxWithdrawal}
                    </li>
                    <li>
                      • Withdrawal Fee will be: {wihtdrawalDetails.withdrawalFee}
                    </li>
                  </ul>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                  </div>
                  <div className="form-group mb-4 d-flex justify-content-center align-items-center m-auto">
                    {(!withdrawAmount || !walletAddress || !otp || CheckWalletAddress || withdrawAmount < amountValidation?.min || withdrawAmount > amountValidation?.max) ? <button type="button" className="btn btn-success active" id="inValid" >
                      Withdraw
                    </button> :
                      <button type="button" className="btn btn-success active" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2">
                        Withdraw
                      </button>}

                  </div>

                </>
              </div>
            </div>
          </div>
        </div>

        {/* Withdrawal Modal */}
        <div className="modal fade" id="Withdraw_modal2" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header flex-column px-8">
                <h3 className="modal-title" id="placeBitLaebl">
                  Confirm
                </h3>
                <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                  <i className="ri-close-fill"></i>
                </button>
              </div>
              <div className="modal-body px-8 py-5">
                <>
                  <div className="form-group mb-4">
                    <h4>
                      You are going to withdraw {selectedCurrency}{" "}
                      {withdrawAmount},
                      please confirm your withdraw by clicking 'Confirm' button
                      below.
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group mb-4">
                        <button type="button" className="btn btn-danger btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" >
                          <span>Cancel</span>
                        </button>
                      </div>
                    </div>

                    <div className="col">
                      <div className="form-group mb-4">
                        <button type="button" className="btn btn-success btn-small w-100 justify-content-center" onClick={() => { WithdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, email ? email : mobile) }}>
                          <span>Confirm</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>

        {/* TradeHistory modal */}
        <div className="modal fade" id="trade_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header flex-column px-8">
                <h3 className="modal-title" id="placeBitLaebl">
                  Trade Details
                </h3>
                <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                  <i className="ri-close-fill"></i>
                </button>
              </div>
              <div className="modal-body px-8 py-5 body_history">
                <div className="tt_data">
                  <div className="tt_item ">
                    <span className="tt_disable">Date &amp; Time</span>
                    <span className="tt_normal">
                      <b><Moment date={tradeHisDetails?.updatedAt} format='MMMM Do YYYY, h:mm:ss a' /> </b>
                    </span>
                  </div>
                  <div className="tt_item">
                    <span className="tt_disable">Credited Currency</span>
                    <span className="tt_normal">
                      <b>{tradeHisDetails?.pay_currency}</b>
                    </span>
                  </div>

                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Side</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.side}</b>
                  </span>
                </div>

                <div className="tt_data">
                  {/* <div className="tt_item">
                    <span className="tt_disable">Transaction Type</span>
                    <span className="tt_normal">
                      <b>{tradeHisDetails?.transaction_type}</b>
                    </span>
                  </div> */}
                  <div className="tt_item ">
                    <span className="tt_disable">
                      Fee
                    </span>
                    <span className="tt_normal">
                      <b>{tradeHisDetails?.fee}</b>
                    </span>
                  </div>

                  {/* <div className="tt_item ">
                    <span className="tt_disable">Fee Type</span>
                    <span className="tt_normal">
                      <b>{tradeHisDetails?.fee_type}</b>
                    </span>
                  </div> */}
                  <div className="tt_item">
                    <span className="tt_disable">Amount</span>
                    <span className="tt_normal">
                      <b>{tradeHisDetails?.amount}</b>
                    </span>
                  </div>
                  <div className="tt_item">
                    <span className="tt_disable">Credited</span>
                    <span className="tt_normal">
                      <b>{tradeHisDetails?.side === "SELL" ? tradeHisDetails?.quantity - tradeHisDetails?.fee : tradeHisDetails?.amount - tradeHisDetails?.fee} {tradeHisDetails?.currency}</b>
                    </span>
                  </div>
                  <div className="tt_item ">
                    <span className="tt_disable">Order Type</span>
                    <span className="tt_normal">
                      <b>{tradeHisDetails?.order_type}</b>
                    </span>
                  </div>

                  <div className="tt_item ">
                    <span className="tt_disable">Quantity</span>
                    <span className="tt_normal">
                      <b>{tradeHisDetails?.quantity}</b>
                    </span>
                  </div>
                  <div className="tt_item ">
                    <span className="tt_disable">Order Id</span>
                    <span className="tt_normal">
                      <b>{tradeHisDetails?.order_id}</b>
                    </span>
                  </div>


                  <div className="tt_item ">
                    <span className="tt_disable">Remarks</span>
                    <span className="tt_normal">
                      <b>WCCEXC Exchange</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Wallet History modal */}
        <div className="modal fade" id="wallet_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header flex-column px-8">
                <h3 className="modal-title" id="placeBitLaebl">
                  Transaction Details
                </h3>
                <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                  <i className="ri-close-fill"></i>
                </button>
              </div>
              <div className="modal-body px-8 py-5 body_history">
                <div className="tt_data">
                  <div className="tt_item">
                    <span className="tt_disable">Date/Time</span>
                    <span className="tt_normal">
                      <b><Moment date={walletHisDetail?.updatedAt} format='MMMM Do YYYY, h:mm:ss A' /></b>
                    </span>
                  </div>
                </div>
                <div className="tt_data">
                  <div className="tt_item">
                    <span className="tt_disable">Coin</span>
                    <span className="tt_normal">
                      <b>{walletHisDetail?.short_name}</b>
                    </span>
                  </div>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Status</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.status}</b>
                  </span>
                </div>

                <div className="tt_data">
                  <div className="tt_item">
                    <span className="tt_disable">Transaction Type</span>
                    <span className="tt_normal">
                      <b>{walletHisDetail?.transaction_type}</b>
                    </span>
                  </div>
                  <div className="tt_item ">
                    <span className="tt_disable">
                      Fee<small> (Incl.of all applicable taxes)</small>
                    </span>
                    <span className="tt_normal">
                      <b>{walletHisDetail?.fee}</b>
                    </span>
                  </div>
                  <div className="tt_item">
                    <span className="tt_disable">Amount</span>
                    <span className="tt_normal">
                      <b>{walletHisDetail?.amount}</b>
                    </span>
                  </div>
                  <div className="tt_item ">
                    <span className="tt_disable">From Address</span>
                    <span className="tt_normal">
                      <b>{walletHisDetail?.from_address}</b>
                    </span>
                  </div>
                  <div className="tt_item ">
                    <span className="tt_disable">To Address</span>
                    <span className="tt_normal">
                      <b>{walletHisDetail?.to_address}</b>
                    </span>
                  </div>

                  <div className="tt_item ">
                    <span className="tt_disable">Transaction Hash</span>
                    <span className="tt_normal">
                      <b>{walletHisDetail?.transaction_hash}</b>
                    </span>
                  </div>
                  <div className="tt_item ">
                    <span className="tt_disable">Date &amp; Time</span>
                    <span className="tt_normal">
                      <b>{moment(walletHisDetail?.updatedAt).format("MMMM Do YYYY, h:mm:ss a")} </b>
                    </span>
                  </div>

                  <div className="tt_item ">
                    <span className="tt_disable">Remarks</span>
                    <span className="tt_normal">
                      <b>WCCEXC Exchange</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Transfer to p2p modal  */}
        <div class="modal fade" id="p2p_modal" tabindex="-1" aria-labelledby="p2p_modalLaebl" aria-hidden="true" >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header flex-column px-8">
                <h3 class="modal-title" id="placeBitLaebl">
                  Transfer Funds
                </h3>
                <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                  <i class="ri-close-fill"></i>
                </button>
              </div>
              <div class="modal-body px-8 py-4">
                <>
                  <div className="form-group mb-4">
                    <input type="text" value={selectedCurrency} disabled />
                  </div>
                  <div className="form-group mb-4">
                    <input type="number" name="amount_val" value={withdrawAmount} placeholder="Amount" onChange={(e) => setWithdrawAmount(e.target.value)} onWheel={(e) => e.target.blur()} />
                  </div>
                  <div className="form-group mb-4">
                    <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" disabled={!withdrawAmount} onClick={() => transferHandler(selectedCurrency, withdrawAmount)}>
                      <span>Transfer</span>
                    </button>
                  </div>
                </>

              </div>
            </div>
          </div>
        </div>

        {/* Transfer to spot modal  */}
        <div class="modal fade" id="spot_modal" tabindex="-1" aria-labelledby="spot_modalLaebl" aria-hidden="true" >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header flex-column px-8">
                <h3 class="modal-title" id="placeBitLaebl">
                  Transfer Funds To Spot
                </h3>
                <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                  <i class="ri-close-fill"></i>
                </button>
              </div>
              <div class="modal-body px-8 py-4">

                <>
                  <div className="form-group mb-4">
                    <input type="text" value={selectedCurrency} disabled />
                  </div>
                  <div className="form-group mb-4">
                    <input type="number" name="amount_val" value={withdrawAmount} placeholder="Amount" onChange={(e) => setWithdrawAmount(e.target.value)} onWheel={(e) => e.target.blur()} />
                  </div>
                  <div className="form-group mb-4">
                    <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" onClick={() => transferHandlerToSpot(selectedCurrency, withdrawAmount)} disabled={!withdrawAmount}>
                      <span>Transfer</span>
                    </button>
                  </div>
                </>

              </div>
            </div>
          </div>
        </div>

      </div>



    </>
  );
};

export default FundPage;
