// const appUrl = "http://103.175.163.162:3005";
const appUrl = "https://api.wccexc.org";
// const appUrl = "http://localhost:3005";
export const deployedUrl = `${window.origin}/`


export const ApiConfig = {
  // =========EndPoints========== //
  getcode: "verify-otp",
  getOtp: "send-otp",
  login: "login",
  register: "register",
  getDetails: "profile",
  changePassword: "change_password",
  updateSettings: "edit_profile",
  googleAuth: "generate-google-qr",
  update2fa: "enable-2fa",
  getHistoricalData: 'historical-data',
  placeOrder: 'place-order',
  pastOrder: 'past-order',
  cancelOrder: 'cancel-order',
  categoryList: 'coin-category-list',
  coinDetails: 'coin-details',
  favoriteCoin: 'favorite-coin',
  favoriteList: 'favorite-list',
  tradeHistory: 'trade-history',
  addFavourite: "exch/addfavcoin",
  getFavouriteList: "exch/getallfavcoin",
  setCurrency: "currency-preference",
  userfunds: "user-wallet",
  generateAddress: "generate-address",
  estimatedPortfolio: "estimated-portfolio",
  forgotpassword: "forgot_password",
  totalrefercount: "total_refer_count",
  referalcode: "user_refer_code",
  withdrawalCurrency: "withdrawal-currency",
  verifyDeposit: 'verify-deposit',
  walletDepositHistory: "wallet-deposit-history",
  walletWithdrawalHistory: "wallet-withdrawal-history",
  addkyc: "submit-kyc",
  activityLogs: "activity-logs",
  skipModel: "skip-model",
  userTradeHistory: "user-trade-history",
  notificationList: "notification-list",
  getPairs: "get-pairs",
  userReferralList: "user_referral_list",
  quick_buy_sell: "quick_buy_sell",
  quickBuySellHistory: "history",
  availabe_staking: "availabe_staking",
  pending_staking_history: "staking_income",
  staking_history: "staking_history",
  place_staking: "place_staking",
  break_staking: "break_staking",
  userEligibility: "user_eligibility",
  getAllProject: "get_all_project",
  getSingleProject: "get_single_project",
  commitProject: "commit_project",
  checkCommitExistence: "check_commit_existence",
  updateCommit: "update_commit",
  getUserCommits: "get_user_commits",
  totalCommits: "user_project_total_commits",
  commitHistory: "user_project_commit_history",
  bannerList: "banner_list",
  addSupportDetails: "add_support_details",
  coin_listing: "coin_listing",

  // P2P Endpoints
  fetchPaymentMethods: "fetch_payment_methods",
  buyOrder: "buy_order",
  sellOrder: "sell_order",
  p2pCoinList: "p2p_coin_list",
  fiatCurrencyList: "fiat_currency_list",
  buyCurrency: "buy_currency",
  myOrders: "my_orders",
  p2pRuntime: "runtime_api",
  rateOrder : "rate_user",
  myAds: "my_ads",
  currentPrice: "current_price",
  getCoinList: "get-coin",
  createNewPost: "create_new_post",
  addBankDetails: "add-bank-details",
  addUpiDetails: "add_user_upi",
  editBankDetails: 'edit-bank-details',
  editUpiDetails: 'edit_user_upi',
  buyRequest: "buy_request",
  notify: "notify_trader",
  notifySeller: "merchant_confirmation",
  notifyMerchent: "trader_confirmation",
  addOrderNotification: "add_order_notification",
  userP2pWallet: "p2p-wallet",
  transferFunds: "swaping_wallets",
  swapHistory: "swapping_history",
  request_refund: "request_refund",
  cancel_posted_add: "cancel_posted_add",


  submitTicket: "submit-ticket",
  getUserTickets: "get-user-tickets",
  replyTicket: "reply-ticket",

  // ============URLs================ //
  baseUrl: `${appUrl}/`,
  appUrl: `${appUrl}/`,
  baseAuth: `${appUrl}/v1/user/`,
  baseAdmin: `${appUrl}/v1/admin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  baseTrans: `${appUrl}/v1/transaction/`,
  baseKyc: `${appUrl}/kyc/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseSwap: `${appUrl}/v1/qbs/`,
  baseP2p: `${appUrl}/v1/p2p/`,
  baseStacking: `${appUrl}/v1/staking/`,
  baseLaunchpad: `${appUrl}/v1/user/launchpad/`,
  baseLaunchpad2: `${appUrl}/v1/admin/launchpad/`,
  baseSupport: `${appUrl}/v1/support/`,

  // ============webSocketUrl================ //
  webSocketUrl: appUrl,

};
